import React, { useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { makeStyles } from "../AppContainer/mui-theme";
import {
  Box,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Fab,
  Grow,
} from "@mui/material";
import {
  flatMap,
  map,
  isEmpty,
  noop,
  trim,
  replace,
  pick,
  lowerCase,
  identity,
  get,
} from "lodash";
import lunr from "lunr";

import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import { useLocation } from "@reach/router";
import { getParams, navigateTo, setParams } from "../../utils/location";

let idx = null;

export default function MenuSearch({
  menuData,
  onChange = noop,
  T = identity,
}) {
  const { classes } = useStyles();
  const [search, setSearch] = useState("");

  const location = useLocation();

  useEffect(() => {
    if (!idx && menuData) {
      const items = flatMap(menuData, (c) =>
        map(c.items, (item) => ({
          ...pick(item, ["id", "name", "description"]),
          categoryName: c.name,
        })),
      );

      if (!isEmpty(items)) {
        idx = lunr(function() {
          this.ref("id");
          this.field("description");
          this.field("name");
          this.field("categoryName");

          console.log("creating search index");
          items.forEach(function(item) {
            this.add(item);
          }, this);
        });
      }
    }
  }, [menuData]);

  const handleChange = useDebouncedCallback(onChange, 300);
  const setSearchParam = useCallback(
    (nextSearchParam) => {
      navigateTo(
        setParams(location, {
          search: nextSearchParam,
        }),
      );
    },
    [location.href],
  );
  const updateSearchParam = useDebouncedCallback(setSearchParam, 500);
  useEffect(() => {
    if (search.length > 1 && idx) {
      const searchInput = replace(
        replace(`${lowerCase(trim(search))}~1`, /(no|without)\s/g, "-"),
        /(only|with)\s/,
        "+",
      );
      try {
        const searchResults = idx.search(searchInput);
        handleChange.callback({ search, searchResults });
      } catch (e) {
        console.log(e);
      }
    } else {
      handleChange.callback({ search, searchResults: [] });
    }
    updateSearchParam.callback(search);
  }, [search, handleChange]);

  useEffect(() => {
    const searchParam = get(getParams(location), "search");

    if (!isEmpty(searchParam)) {
      setSearch(searchParam);
    }
  }, []);

  return (
    <Box sx={{ padding: 2, maxWidth: 450 }}>
      <Grid container direction="column" spacing={0}>
        <Grid item>
          <TextField
            className={classes.search}
            InputProps={{
              style: { color: "inherit" },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  <Grow in={!isEmpty(search)}>
                    <IconButton size="small" onClick={() => setSearch("")}>
                      <CancelIcon color="secondary" />
                    </IconButton>
                  </Grow>
                </InputAdornment>
              ),
            }}
            placeholder={T(
              "You can use 'no' or 'without' and 'only' to filter",
            )}
            size="small"
            fullWidth
            id="search-menu-input"
            label={T("Search...")}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            variant="standard"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles()((theme) => ({
  search: {
    background: "rgba(255,255,255,0.15)",
    "& > .MuiFormLabel-root": {
      color: "inherit",
    },
  },
  avatar: {
    height: 80,
    width: 80,
    [theme.breakpoints.down("md")]: {
      height: 60,
      width: 60,
    },
  },
}));
