// extracted by mini-css-extract-plugin
export var CategoryDescription = "index-module--CategoryDescription--f1c6b";
export var CategoryGiftIcon = "index-module--CategoryGiftIcon--9e0c7";
export var CategoryItem = "index-module--CategoryItem--6348b";
export var CategoryItems = "index-module--CategoryItems--d0136";
export var CategoryLabel = "index-module--CategoryLabel--d06a9";
export var CategoryMenu = "index-module--CategoryMenu--f0cbf";
export var CategoryName = "index-module--CategoryName--43d1d";
export var CategoryTitle = "index-module--CategoryTitle--7a424";
export var CheckDealOrItemNotice = "index-module--CheckDealOrItemNotice--e5bde";
export var CheckoutOpen = "index-module--CheckoutOpen--d7653";
export var CheckoutSideBarContainer = "index-module--CheckoutSideBarContainer--bae0c";
export var CurrentSection = "index-module--CurrentSection--8e1f4";
export var CurrentSectionWithColor = "index-module--CurrentSectionWithColor--178f9";
export var CurrentSectionWithScale = "index-module--CurrentSectionWithScale--667a7";
export var DealCategoryTitleMobile = "index-module--DealCategoryTitleMobile--07cf6";
export var DisplayCategoriesImages = "index-module--DisplayCategoriesImages--50e18";
export var GiftCategory = "index-module--GiftCategory--187d9";
export var GiftIcon = "index-module--GiftIcon--5b49c";
export var Guest = "index-module--Guest--0bdbd";
export var Image = "index-module--Image--293f4";
export var LightLabel = "index-module--LightLabel--36e6c";
export var Margin = "index-module--Margin--ef4a3";
export var MenuContainer = "index-module--MenuContainer--dc6a0";
export var MenuNotice = "index-module--MenuNotice--28935";
export var NoShadowDesktopMenu = "index-module--NoShadowDesktopMenu--d43ad";
export var Open = "index-module--Open--1110b";
export var PromoCodeMobileButton = "index-module--PromoCodeMobileButton--991c8";
export var RTL = "index-module--RTL--3302c";
export var ResponsiveCategoryMenuCompensation = "index-module--ResponsiveCategoryMenuCompensation--f1ad7";
export var ResponsiveHeader = "index-module--ResponsiveHeader--2b3a5";
export var ResponsiveHeaderCheckoutOpen = "index-module--ResponsiveHeaderCheckoutOpen--4e2b1";
export var Scrolling = "index-module--Scrolling--54e15";