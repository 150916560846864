import React from "react";
import AppContainer from "../AppContainer";
import Button from "../Button";
import MenuItem from "../Item";
import * as styles from "./index.module.scss";

export default function(props) {
  const {
    T,
    appStyles,
    onClose,
    deal,
    onDealClicked = _.noop,
    defaultItemImage = null,
  } = props;
  const [loading, setLoading] = React.useState();
  const onClick = (e) => {
    setLoading(true);
    onDealClicked(e);
  };
  return (
    <AppContainer.CenteredColumn>
      <h3 style={{ padding: 26 }}>
        {deal
          ? T("Check out this deal!")
          : T(
              "Unfortunately the deal is not available in this location/service option",
            )}
      </h3>
      {deal ? (
        <React.Fragment>
          <MenuItem
            style={{ width: "100%" }}
            isDeal
            imageKey={deal.imageKey}
            imagePreview={deal.imagePreview}
            T={T}
            appStyles={appStyles}
            key={deal.id}
            title={deal.header}
            description={deal.description}
            defaultImage={defaultItemImage}
            totalDealPrice={deal.totalDealPrice}
            isLocked={deal.isLocked}
            userUnlockedDeal={deal.userUnlockedDeal}
            lockDescription={deal.lockMessage}
            classNames={styles.CategoryItem}
            loading={loading}
          />
          <Button
            appStyles={appStyles}
            onClick={onClick}
            centered
            style={{ marginTop: 16 }}
          >
            {T("Go to Deal!")}
          </Button>
          <Button
            appStyles={appStyles}
            onClick={onClose}
            centered
            style={{ marginTop: 16 }}
            linkStyle
          >
            {T("Next time")}
          </Button>
        </React.Fragment>
      ) : (
        <Button
          appStyles={appStyles}
          onClick={onClose}
          centered
          style={{ marginTop: 16 }}
        >
          {T("Close")}
        </Button>
      )}
    </AppContainer.CenteredColumn>
  );
}
