import React, { useState } from "react";
import { find, get } from "lodash";
import { Store } from "@mui/icons-material";
import Button from "../Button";
import * as styles from "./index.module.scss";
import BranchesList from "../FindLocationView/BranchesList";
import SeeMenuAnyway from "../FindLocationView/SeeMenuAnyway";
import Modal from "../Modal";
import { navigateTo, setParams } from "../../utils/location";

const OrderBranch = ({
  selectedBranch,
  appStyles,
  T,
  onSetBranch,
  locations,
  branches,
  servingOption,
  branchesAvailability,
  params,
  selectedServingOption,
  pageContext,
  deliveryOptions,
  order,
  user
}) => {
  const [showSeeMenuAnywayModal, setShowSeeMenuAnywayModal] = useState(false);
  const [locationChosenForModal, setLocationChosenForModal] = useState(null);

  const handleSelectBranch = (locationId, disableOrder) => () => {
    if (disableOrder) {
      setShowSeeMenuAnywayModal(true);
      setLocationChosenForModal(locationId);
      return;
    }

    onSetBranch(locationId);
  };

  const handleOrderNowClick = () => {
    onSetBranch(selectedBranch.branchId);
    navigateTo(
      setParams("/order", {
        ...params,
        branchId: selectedBranch.id,
        branchName: selectedBranch.name,
      })
    );
  };

  const handleSeeMenuModalClose = () => {
    setShowSeeMenuAnywayModal(false);
    setLocationChosenForModal(null);
  };

  const currencySymbol = get(pageContext, "business.currencySymbol");
  const metric = get(pageContext, "businessAppConfiguration.metric");

  return (
    <>
      <Store className={styles.icon} style={{ color: appStyles.accentColor }} />
      <div
        className={styles.wrapper}
        style={{ color: get(appStyles, "Card.color") }}
      >
        <span style={{ ...appStyles.CardTitle, fontSize: "1.5rem" }}>
          {T("Ordering requires a location to be selected")}
        </span>
        <span style={{ marginTop: 6, marginBottom: 12 }}>
          {T("We found that you previously selected:")}
          <p style={{ fontSize: "1.2rem", margin: 5 }}>{selectedBranch.name}</p>
          <p style={{ fontSize: "1.1rem", margin: 2 }}>
            {selectedBranch.address}
          </p>
        </span>
        <span>
          {T("Please confirm or select another location to start ordering")}
        </span>
      </div>
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <Button
          appStyles={appStyles}
          centered
          style={{ marginTop: 16, maxWidth: "75%" }}
          onClick={handleOrderNowClick}
        >
          {T("Continue order with selected location")}
        </Button>
      </div>
      <BranchesList
        params={params}
        onSelectedBranch={handleSelectBranch}
        locations={locations}
        deliveryOptions={deliveryOptions}
        branches={branches}
        appStyles={appStyles}
        T={T}
        servingOption={servingOption}
        currencySymbol={currencySymbol}
        metric={metric}
        branchesAvailability={branchesAvailability}
        userCoords={get(order, "deliveryAddress") ||
          get(user, "userLocatedAddress")}
      />
      <Modal
        open={showSeeMenuAnywayModal}
        onClose={handleSeeMenuModalClose}
        appStyles={appStyles}
        center
      >
        <SeeMenuAnyway
          params={params}
          T={T}
          appStyles={appStyles}
          branchAvailability={find(branchesAvailability, {
            branchId: locationChosenForModal,
          })}
          servingOptionTag={get(selectedServingOption, "servingOptionTag")}
          onClick={
            showSeeMenuAnywayModal &&
            (() => {
              onSetBranch(locationChosenForModal);
              handleSeeMenuModalClose();
            })
          }
        />
      </Modal>
    </>
  );
};

export default OrderBranch;
