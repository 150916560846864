import classnames from "classnames";
import { withPrefix } from "gatsby";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import React from "react";
import Modal from "../Modal";
import Button from "../Button";
import InfoIcon from "../icons/Info.svg";
import * as styles from "./index.module.scss";

export default class AllergensInformation extends React.Component {
  state = { allergensModalOpen: false };

  openAllergensModal = () => this.setState({ allergensModalOpen: true });
  closeAllergensModal = () => this.setState({ allergensModalOpen: false });

  render() {
    const { appStyles = {}, T, allergens, checkoutOpen } = this.props;

    if (isEmpty(allergens)) {
      return null;
    }

    return (
      <React.Fragment>
        <Button
          appStyles={appStyles}
          onClick={this.openAllergensModal}
          centered
          slim
          style={{ ...appStyles.Button, ...appStyles.ExternalLinkButton }}
          classNames={classnames(
            styles.AllergensButton,
            appStyles.rtl && styles.RTL,
          )}
        >
          <InfoIcon />
          {T("Allergens")}
        </Button>
        <Modal
          open={this.state.allergensModalOpen}
          onClose={this.closeAllergensModal}
          appStyles={appStyles}
          style={{ 
            background: appStyles.cardBackgroundColor,
            width: "100%"
          }}
        >
          <h2 style={{ ...appStyles.CardTitle, textAlign: "center" }}>
            {T("Food Allergens")}
          </h2>
          <div className={styles.Wrapper}>
            {map(allergens, (allergen) => (
              <span className={styles.ColumnElement} key={allergen.title}>
                <img
                  src={withPrefix(allergen.path)}
                  className={classnames(
                    styles.AllergenIcon,
                    appStyles.rtl && styles.RTL,
                  )}
                />
                <span>{allergen.title}</span>
              </span>
            ))}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
