import classnames from 'classnames';
import React from 'react';
import Button from '../Button';
import * as styles from './index.module.scss';

export default ({ T, appStyles, onClick, style }) => (
  <Button
    classNames={classnames(styles.PromoCodeMobileButton, appStyles.rtl && styles.RTL)}
    appStyles={appStyles}
    onClick={onClick}
    centered
    slim
    style={{...style, ...appStyles.PromoCodeButton}}
  >
    {T('Add a promo code')}
  </Button>
);
