import { Avatar, Badge, Chip, Grid } from "@mui/material";
import { makeStyles } from "../AppContainer/mui-theme";
import { Favorite, RestaurantMenu } from '@mui/icons-material';
import { get } from "lodash";
import React from "react";
import DealIcon from "../icons/Deals.svg";
import * as styles from "./index.module.scss";
import { getCategoryImage } from "../../utils/categories";

const getIconForCategory = (type) => {
  const iconsMap = {
    "Deals": <DealIcon/>,
    "Favorites": <Favorite />,
    default: <RestaurantMenu />,
  }

  return iconsMap[type] || iconsMap.default;
}

export default function CategoryLabel({
  category,
  showCount,
  appStyles = {},
  isCurrent,
  variant = "circular",
  refEl,
  T
}) {
  const { classes } = useStyles();
  const displayCategoriesImages = appStyles.displayCategoriesImages;
  const flexAlign = displayCategoriesImages ? "center" : "start";
  const textAlign = displayCategoriesImages ? "center" : "left";

  return (
    <Grid
      ref={refEl}
      container
      direction="column"
      alignItems={flexAlign}
      spacing={1}
    >
      {appStyles.displayCategoriesImages && (
        <Grid item>
          <Badge
            invisible={!showCount}
            overlap="circular"
            color="primary"
            badgeContent={showCount ? get(category, "items.length", 0) : "0"}
            
          >
            <Avatar
              variant={variant}
              src={getCategoryImage(category)}
              className={classes.avatar}
              style={{...appStyles.CategoryMenuItems}}
            >
              {category && getIconForCategory(category.name)}
            </Avatar>
          </Badge>
        </Grid>
      )}
      <Grid item alignContent={flexAlign}>
        <div
          style={{
            textAlign,
            ...(isCurrent && {}),
            ...appStyles.CategoryMenuLabel,
          }}
        >
          {T(category.name)}
          {Boolean(showCount) && !appStyles.displayCategoriesImages && (
            <>
              {" "}
              <Chip label={category.items.length} />
            </>
          )}
        </div>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles()((theme) => ({
  avatar: {
    height: 80,
    width: 80,
    [theme.breakpoints.down("md")]: {
      height: 60,
      width: 60,
    },
  },
}));
