// extracted by mini-css-extract-plugin
export var BranchDetailsDesktop = "index-module--BranchDetailsDesktop--3883f";
export var BranchHeaderSizes = "index-module--BranchHeaderSizes--065eb";
export var ChevronDown = "index-module--ChevronDown--b51de";
export var Content = "index-module--Content--8a486";
export var HeaderDesktop = "index-module--HeaderDesktop--08add";
export var HideOnDesktop = "index-module--HideOnDesktop--6a88e";
export var HideOnMobile = "index-module--HideOnMobile--93a3f";
export var ListItemContent = "index-module--ListItemContent--9474e";
export var LocationDetails = "index-module--LocationDetails--03b47";
export var LocationDetailsIcons = "index-module--LocationDetailsIcons--a2844";
export var LocationImageCaption = "index-module--LocationImageCaption--60612";
export var LocationImageWrapper = "index-module--LocationImageWrapper--068c6";
export var MenuHeader = "index-module--MenuHeader--fb7b2";
export var MenuHeaderInfoPanel = "index-module--MenuHeaderInfoPanel--9662f";
export var NutritionFacts = "index-module--NutritionFacts--979c1";
export var PencilIconMobile = "index-module--PencilIconMobile--211ce";
export var RTL = "index-module--RTL--c7682";
export var Shadow = "index-module--Shadow--0959e";
export var Shown = "index-module--Shown--71a4e";
export var TableCodeHeader = "index-module--TableCodeHeader--7d12a";
export var fadeAndGrowIn = "index-module--fadeAndGrowIn--d7fbb";