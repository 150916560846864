import classnames from "classnames";
import React from "react";
import { navigateTo } from "../../utils/location";
import { getOS } from "../../utils/system";
import BackgroundImageExtrnalLink from "../BackgroundImageExtrnalLink";
import Button from "../Button";
import ExternalLink from "../ExternalLink";
import ClockIcon from "../icons/Clock.svg";
import LocationPinIcon from "../icons/LocationPin.svg";
import PhoneIcon from "../icons/Phone.svg";
import * as styles from "./index.module.scss";

export default ({
  T,
  appStyles,
  branch,
  classNames,
  style,
  color,
  moreThanOneBranchAvailable,
}) => {
  const { Card = {}, links = {} } = appStyles;

  return (
    <div className={classNames} style={style}>
      <div className={styles.Content}>
        {links.nutrition && (
          <BackgroundImageExtrnalLink
            appStyles={appStyles}
            to={links.nutrition}
            linkStyle
            style={appStyles.rtl ? { paddingRight: 0 } : { paddingLeft: 0 }}
            classNames={classnames(styles.NutritionFacts, styles.HideOnDesktop)}
          >
            {T("See our nutrition facts")}
          </BackgroundImageExtrnalLink>
        )}
        {branch.address && (
          <small
            className={classnames(
              styles.LocationDetailsIcons,
              appStyles.rtl && styles.RTL,
            )}
          >
            <LocationPinIcon />
            <ExternalLink
              nativeMode={
                typeof window === "undefined" ? false : window.nativeMode
              }
              link={
                getOS() == "iOS"
                  ? `maps://?q=${branch.latitude},${branch.longitude}`
                  : T(`https://maps.google.com/maps?q=${branch.latitude},${
                      branch.longitude
                    }`)
              }
              appStyles={appStyles}
              style={{ color, ...appStyles.ExternalLinkButton || {} }}
            >
              {T(branch.address)}
            </ExternalLink>
          </small>
        )}
        {branch.phoneNumber && (
          <small
            className={classnames(
              styles.LocationDetailsIcons,
              appStyles.rtl && styles.RTL,
            )}
          >
            <PhoneIcon />
            <ExternalLink
              nativeMode={
                typeof window === "undefined" ? false : window.nativeMode
              }
              link={`tel:${branch.phoneNumber}`}
              appStyles={appStyles}
              style={{ color ,...appStyles.ExternalLinkButton || {} }}
            >
              {branch.phoneNumber}
            </ExternalLink>
          </small>
        )}
        {branch.openHours && (
          <small
            className={classnames(
              styles.LocationDetailsIcons,
              appStyles.rtl && styles.RTL,
            )}
          >
            <ClockIcon />
            {branch.openHours}
          </small>
        )}
        {moreThanOneBranchAvailable && (
          <Button
            slim
            centered
            appStyles={appStyles}
            style={{ height: 40 }}
            onClick={() => navigateTo("/find-location")}
            classNames={styles.HideOnDesktop}
          >
            {T("Change Location")}
          </Button>
        )}
      </div>
    </div>
  );
};
