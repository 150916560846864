import { get } from "lodash";
import { cdnRoot } from "./media";

export const getCategoryImage = (category) => {
  const imageKey = get(category, "imageKey");

  if (category.holdsItems) {
    return (
      imageKey &&
      `${cdnRoot}/menus/${get(category, "imageKey")}/300-_-contain_v4`
    );
  }

  return (
    imageKey &&
    `${cdnRoot}/menu-categories/${get(category, "imageKey")}/300-_-contain_v4`
  );
};