import get from "lodash/get";
import React from "react";
import Button from "../Button";
import Card from "../Card";
import Loader from "../Loader";
import * as styles from "./index.module.scss";

export default class extends React.Component {
  render() {
    const { T, appStyles, onClose, style, tight, user } = this.props;

    const errorMessage = false && user.tradePointsError;
    const loading = user.tradingPointsForCoupon;

    return [
      <Card appStyles={appStyles} style={{ marginTop: 36 }}>
        <Card.Title appStyles={appStyles} style={{ fontSize: "1.1rem" }}>
          {loading && T("Redeeming Points for Coupon...")}
          {!loading &&
            errorMessage &&
            T("Failed to redeeming points for coupon...")}
          {!loading && !errorMessage && T("Your coupon is now available!")}
        </Card.Title>
        <Card.Content>
          {get(user, "lastPointTradeCoupon.coupon.title")}
        </Card.Content>
        <Card.Content>
          <small>{get(user, "lastPointTradeCoupon.coupon.description")}</small>
          {errorMessage && !loading && (
            <div>{errorMessage}</div>
          )}
          {loading && (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: 16,
                width: 280,
              }}
            >
              <Loader appStyles={appStyles} />
            </div>
          )}
        </Card.Content>
      </Card>,
      <Button
        appStyles={appStyles}
        onClick={onClose}
        centered
        style={{ marginTop: 16 }}
      >
        {T("Continue")}
      </Button>,
    ];
  }
}
