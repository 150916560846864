import React, { useState } from "react";
import { get } from "lodash";
import { Business, SettingsInputAntennaTwoTone } from "@mui/icons-material";
import * as styles from "./index.module.scss";
import Button from "../Button";
import  MapboxAddressInput from "../FindLocationView/address-input";
import GoogleAddressInput from "../FindLocationView/AddressInput";
import { convertMapboxPlaceToUserAddress } from "../../utils/geoLocation";
const OrderAddress = ({
  currentBranch,
  servingOption,
  businessAppConfiguration,
  appStyles,
  T,
  user,
  order,
  onSetAddressClick
}) => {
  
  //create state to save selected user address
  const [userAddress, setUserAddress] = useState(get(order, "deliveryAddress"));
  
  const dontUseMapbox =
  businessAppConfiguration.useGoogleMapsForGeocoding ||
  appStyles.locale !== "en-US";

  const previousAddresses = get(user, "userDetails.data.seenAddresses", []);
  const {shouldAllowInaccurateAddress} = businessAppConfiguration; 
  const onSelectedMapboxAddress = (place) => {
    if (place.savedAddress){
      setUserAddress({
          ..._.pick(place, [
            "geoPoint",
            "zipCode",
            "city",
            "number",
            "street",
            "state",
            "description",
          ]),
          isGeometricCenter: true,
        });
      }else{
        setUserAddress(convertMapboxPlaceToUserAddress(place));
      }
  };

  const buttonEnabled = get(userAddress, "geoPoint");
  return (
    <React.Fragment>
      <Business
        className={styles.icon}
        style={{ color: appStyles.accentColor }}
      />
      <h2 style={{ textAlign: "center", color: appStyles.accentColor }}>
        {T("Delivery ordering")}
      </h2>
      <div
        className={styles.wrapper}
        style={{ color: get(appStyles, "Card.color") }}
      >
        <span style={{ ...appStyles.CardTitle, fontSize: "1.5rem" }}>
          {T("Delivery requires an address")}
        </span>
        <span style={{ marginBottom: 16, marginTop: 6 }}>
          {T(
            "Please enter your address to start ordering"
          )}
        </span>
            <MapboxAddressInput
              usingCurrentLocation={false}
              setUsingCurrentLocation={()=>{}}
              onSelected={onSelectedMapboxAddress}
              placeholder={ T("Enter address with street number")}
              T={T}
              appStyles={appStyles}
              locationBias={_.get(currentBranch, "geoPoint")}
              showLocateMe={false}
              onLocateMeFailure={()=>{}}//this.handleLocateMeError}
              onLocateMeSuccess={()=>{}}//this.handleNewUserCoords}
              autoLocateMeOnMount={false}
              shouldAllowInaccurateAddress={false}
              shouldShowHouseNumberInput={true}
              setAddressWithHouseNumber={(houseNumber) => {}}
              userEnteredInvalidHouseNumber={()=>{}}
              initialValue={null}
              accurateOnly={true}
              onClear={()=>{setUserAddress(null)}}
              previousAddresses={previousAddresses}
            />
      </div>
      <Button
        appStyles={appStyles}
        centered
        style={{ marginTop: 16 , ...!buttonEnabled?{ backgroundColor: "#cccccc"} :{} }}
        onClick={buttonEnabled ? () => onSetAddressClick(userAddress) : () => {}}
      >
        {T("Select the address")}
      </Button>
    </React.Fragment>
  );
};

export default OrderAddress;
